<template>
  <b-container fluid v-if="$root.$data.me.access.ROY">
    <b-row v-if="!apOptions || apOptions.length === 0">
      <b-col>
        <template v-if="apOptions === null && !apError">
          Loading available Accounting Periods<b-spinner small label="Spinning"></b-spinner>
        </template>

        <span v-if="apOptions && apOptions.length === 0">No data available</span>
        <span v-if="apError">{{ apError }}</span>
      </b-col>
    </b-row>
    <b-row v-if="apOptions && apOptions.length > 0">
      <b-col>
        <page-snippet name="analytics" />
        <b-card>
          <div slot="header">
            Filters / Options
          </div>

          <b-container fluid class="p-0" >
            <b-row>
              <b-col cols="12" md="6" lg="6" xl="6" class="mb-4">
                <div class="mr-2">View:</div>
                <b-button-group>
                  <b-button
                      v-on:click="view = 'top-lists'; target = 'Quantity'; filterSerial++"
                      v-bind:class="{ active: view == 'top-lists' && target == 'Quantity'}">
                    <span v-if="sortDirection == 'desc'">Top</span> 
                    <span v-if="sortDirection == 'asc'">Bottom</span> 
                    Quantity
                  </b-button>
                  <b-button
                      v-on:click="view = 'top-lists'; target = 'ProviderIncome'; filterSerial++"
                      v-bind:class="{ active: view == 'top-lists' && target == 'ProviderIncome' }">
                    <span v-if="sortDirection == 'desc'">Top</span> 
                    <span v-if="sortDirection == 'asc'">Bottom</span> 
                    Income
                  </b-button>
                  <b-button 
                      v-on:click="view = 'table'"
                      v-bind:class="{ active: view == 'table' }">
                   View/export transactions
                  </b-button>
                </b-button-group>
              </b-col>

              <b-col cols="12" md="6" lg="6" xl="6" class="mb-4" v-if="false">
                <div class="mr-2">Sort:</div>
                <b-button-group>
                  <b-button
                      v-on:click="sortDirection = 'asc'"
                      v-bind:class="{ active: sortDirection == 'asc'}">
                    Asc.
                  </b-button>
                  <b-button
                      v-on:click="sortDirection = 'desc'"
                      v-bind:class="{ active: sortDirection == 'desc'}">
                    Desc.
                  </b-button>
                </b-button-group>
              </b-col>

              <b-col cols="12" md="6" lg="6" xl="6" class="mb-4">
                <div class="mr-2">Period:</div>

                <span class="mt-2" v-if="apOptions && apOptions.length > 0">
                  <b-form-select v-model="filter.apFrom" :options="apOptions" size="sm" class="mr-2"></b-form-select>
                  - 
                  <b-form-select v-model="filter.apTo" :options="apOptions" size="sm" class="mr-2"></b-form-select>
                </span>
              </b-col>

              <b-col cols="12" md="3" lg="3" xl="2" class="mb-4">
                <div class="mr-2">Results:</div>

                <b-button-group class="mr-2">
                  <b-button
                      v-on:click="perPage = 5"
                      v-bind:class="{ active: perPage == 5 }">
                    5
                  </b-button>
                  <b-button
                      v-on:click="perPage = 10"
                      v-bind:class="{ active: perPage == 10 }">
                    10
                  </b-button>
                  <b-button
                      v-on:click="perPage = 25"
                      v-bind:class="{ active: perPage == 25 }">
                    25
                  </b-button>
                </b-button-group>
              </b-col>

              <b-col cols="12" md="6" lg="6" xl="4" class="mb-4">
                Number format:<br>
                <b-button-group class="mr-2">
                  <b-button
                      v-on:click="numberLocale = 'intl'"
                      v-bind:class="{ active: numberLocale == 'intl' }">
                    1 000.00
                  </b-button>
                  <b-button
                      v-on:click="numberLocale = 'en'"
                      v-bind:class="{ active: numberLocale == 'en' }">
                    1,000.00
                  </b-button>
                  <b-button
                      v-on:click="numberLocale = 'nl'"
                      v-bind:class="{ active: numberLocale == 'nl' }">
                    1.000,00
                  </b-button>
                </b-button-group>
              </b-col>

              <b-col v-if="$root.$data.me.PortalAdmin" cols="12" md="6" lg="6" xl="4" class="mb-4">
                Payable:<br>
                <b-button-group class="mr-2">
                  <b-button
                      v-on:click="filter.payable = 'both'"
                      v-bind:class="{ active: filter.payable == 'both' }">
                    both
                  </b-button>
                  <b-button
                      v-on:click="filter.payable = 'yes'"
                      v-bind:class="{ active: filter.payable == 'yes' }">
                    yes
                  </b-button>
                  <b-button
                      v-on:click="filter.payable = 'no'"
                      v-bind:class="{ active: filter.payable == 'no' }">
                    no
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="4" xl="4" class="mb-4">
                Tracks:
                <ApiLookup v-model="filter.selectedTracks" source="analytics/find-tracks" :filter="filter" :context="'RoyaltyTransactions'" />
              </b-col>

              <b-col cols="12" md="6" lg="4" xl="4"  class="mb-4">
                Albums:
                <ApiLookup v-model="filter.selectedAlbums" source="analytics/find-albums" :filter="filter" :context="'RoyaltyTransactions'" />
              </b-col>

              <b-col cols="12" md="6" lg="4" xl="4"  class="mb-4">
                Performing Artist:
                <ApiLookup v-model="filter.selectedAttributions" source="analytics/find-attributions" :filter="filter" :context="'RoyaltyTransactions'" />
              </b-col>
              
              <b-col cols="12" md="6" lg="4" xl="4"  class="mb-4">
                Outlets:
                <ApiLookup v-model="filter.selectedOutlets" source="analytics/find-outlets" :filter="filter" :context="'RoyaltyTransactions'" />
              </b-col>

              <b-col cols="12" md="6" lg="4" xl="4"  class="mb-4">
                Countries:
                <ApiLookup v-model="filter.selectedCountries" source="analytics/find-countries" :filter="filter" :context="'RoyaltyTransactions'" />
              </b-col>

              <b-col cols="12" md="6" lg="4" xl="4"  class="mb-4">
                Statement Reference
                <ApiLookup v-model="filter.selectedRefs" source="analytics/find-statement-refs" :filter="filter"  />
              </b-col>

              <b-col cols="12" md="6" lg="4" xl="4"  class="mb-4" v-if="$root.$data.me.IsSuperUser || $root.$data.me.PortalSuperUser">
                Beneficiaries
                <ApiLookup v-model="filter.selectedBeneficiaries" source="analytics/find-beneficiaries" :filter="filter"  />
              </b-col>

              <b-col cols="12" md="6" lg="4" xl="4"  class="mb-4" v-if="$root.$data.me.IsSuperUser || $root.$data.me.PortalSuperUser">
                Companies
                <ApiLookup v-model="filter.selectedCompanies" source="analytics/find-companies" :filter="filter"  />
              </b-col>

              <b-col cols="12" md="6" lg="4" xl="4"  class="mb-4">
                Transaction Type
                <ApiLookup v-model="filter.selectedTransactionTypes" source="analytics/find-transaction-types" :filter="filter"  />
              </b-col>
              
              <b-col cols="12" md="6" lg="4" xl="4"  class="mb-4">
                Accounting Generations
                <ApiLookup v-model="filter.selectedAccountingGenerations" source="analytics/find-accounting-generations" :filter="filter" :search-empty="true" />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-button variant="primary" class="mr-2" v-on:click="filterSerial++">Apply</b-button>
                <b-button variant="secondary" v-on:click="clearFilters">Reset</b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>

      <b-col cols="12" v-if="view == ''">
        <b-alert show>Please pick a view at the top. "Top quantity", "Top income" or "View/export transactions"</b-alert>
      </b-col>
    </b-row>

    <b-row class="mt-4" v-if="view == 'table' && apOptions && apOptions.length > 0">
      <b-col>
        <RoyaltyTransactionTable v-on:item-click="filterItem($event)" :watch="filterSerial" :filter="filter" :perPage="perPage" :numberLocale="numberLocale" :sortDirection="sortDirection" />
      </b-col>
    </b-row>

    <b-row v-if="view == 'top-lists' && apOptions.length > 0">
      <b-col class="mt-4" cols="12" md="6" lg="4" xl="3"><RoyaltyAnalyticsList :sortDirection="sortDirection" :canSelect="true" v-on:item-click="filterItem($event)" :watch="filterSerial" :numberLocale="numberLocale" :filter="filter" :target="target" :perPage="perPage" aggName="track" title="Tracks" columnTitle="Track" /></b-col>
      <b-col class="mt-4" cols="12" md="6" lg="4" xl="3"><RoyaltyAnalyticsList :sortDirection="sortDirection" :canSelect="true" v-on:item-click="filterItem($event)" :watch="filterSerial" :numberLocale="numberLocale" :filter="filter" :target="target" :perPage="perPage" aggName="album" title="Albums" columnTitle="Album" /></b-col>
      <b-col class="mt-4" cols="12" md="6" lg="4" xl="3"><RoyaltyAnalyticsList :sortDirection="sortDirection" :canSelect="true" v-on:item-click="filterItem($event)" :watch="filterSerial" :numberLocale="numberLocale" :filter="filter" :target="target" :perPage="perPage" aggName="attribution" title="Artist" columnTitle="Artist" /></b-col>
      <b-col class="mt-4" cols="12" md="6" lg="4" xl="3"><RoyaltyAnalyticsList :sortDirection="sortDirection" :canSelect="true" v-on:item-click="filterItem($event)" :watch="filterSerial" :numberLocale="numberLocale" :filter="filter" :target="target" :perPage="perPage" aggName="reference" title="Reference" columnTitle="Reference" /></b-col>
      <b-col class="mt-4" cols="12" md="6" lg="4" xl="3"><RoyaltyAnalyticsList :sortDirection="sortDirection" :canSelect="true" v-on:item-click="filterItem($event)" :watch="filterSerial" :numberLocale="numberLocale" :filter="filter" :target="target" :perPage="perPage" aggName="outlet" title="Outlets" columnTitle= "Outlet" /></b-col>
      <b-col class="mt-4" cols="12" md="6" lg="4" xl="3"><RoyaltyAnalyticsList :sortDirection="sortDirection" :canSelect="true" v-on:item-click="filterItem($event)" :watch="filterSerial" :numberLocale="numberLocale" :filter="filter" :target="target" :perPage="perPage" aggName="country" title="Countries" columnTitle= "Country" :titleLabels="$root.$data.countries" /></b-col>
      <b-col class="mt-4" cols="12" md="6" lg="4" xl="3"><RoyaltyAnalyticsList :sortDirection="sortDirection" :canSelect="true" v-on:item-click="filterItem($event)" :watch="filterSerial" :numberLocale="numberLocale" :filter="filter" :target="target" :perPage="perPage" aggName="accountingPeriod" title="Periods" columnTitle="Period" /></b-col>
      <b-col class="mt-4" cols="12" md="6" lg="4" xl="3"><RoyaltyAnalyticsList :sortDirection="sortDirection" :canSelect="true" v-on:item-click="filterItem($event)" :watch="filterSerial" :numberLocale="numberLocale" :filter="filter" :target="target" :perPage="perPage" aggName="transactionType" title="Transaction Types" columnTitle="Type" /></b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'RoyaltyAnalytics',
    components: {
        RoyaltyAnalyticsList: () => import('@/components/RoyaltyAnalyticsList.vue'),
        RoyaltyTransactionTable: () => import('@/components/RoyaltyTransactionTable.vue'),
        ApiLookup: () => import('@/components/ApiLookup.vue'),
        'page-snippet': () => import('@/components/PageSnippet.vue')
    },
    data () {
      return {
        filterSerial: 1,
        sortDirection: 'desc',
        filter: {
          apFrom: '',
          apTo: '',
          payable: 'both',
          selectedTracks: [],
          selectedAlbums: [],
          selectedAttributions: [],
          selectedRefs: [],
          selectedCompanies: [],
          selectedBeneficiaries: [],
          selectedOutlets: [],
          selectedCountries: [],
          selectedTransactionTypes: [],
          selectedCurrencies: [],
        },
        trendsLoading: false,
        target: 'ProviderIncome',
        perPage: 5,
        apOptions: null,
        apError: null,
        numberLocale: 'nl',
        view: '',
      }
    },
    props: {
      inputParams: Object
    },
    methods: {
      filterItem (data) {
        let item = data.item
        if (data.type == 'album') {
          this.filter.selectedAlbums.push({key: item.key, value: item.title})
        } else if (data.type == 'track') {
          this.filter.selectedTracks.push({key: item.key, value: item.title})
        } else if (data.type == 'reference') {
          this.filter.selectedRefs.push({key: item.key, value: item.title})
        } else if (data.type == 'outlet') {
          this.filter.selectedOutlets.push({key: item.key, value: item.title})
        } else if (data.type == 'country') {
          this.filter.selectedCountries.push({key: item.key, value: item.title})
        } else if (data.type == 'transactionType') {
          this.filter.selectedTransactionTypes.push({key: item.key, value: item.title})
        } else if (data.type == 'attribution') {
          this.filter.selectedAttributions.push({key: item.key, value: item.title})
        } else if (data.type == 'accountingPeriod') {
          this.filter.apFrom = item.key
          this.filter.apTo = item.key
        }
      },
      clearFilters () {
        this.clearArray(this.filter.selectedCountries)
        this.clearArray(this.filter.selectedTracks)
        this.clearArray(this.filter.selectedAlbums)
        this.clearArray(this.filter.selectedRefs)
        this.clearArray(this.filter.selectedBeneficiaries)
        this.clearArray(this.filter.selectedCompanies)
        this.clearArray(this.filter.selectedOutlets)
        this.clearArray(this.filter.selectedAttributions)
        this.clearArray(this.filter.selectedTransactionTypes)
      },
      clearArray(arr) {
        while (arr.length > 0)
          arr.pop()
      }
    },
    computed: {
    },
    mounted () {
      this.$root.$data.crumbs = [
        {to: { name: 'royalty-analytics' }, text: 'Royalty analytics'}
      ]

      let params = this.$route.query
      if (params.view)
        this.view = params.view

      if (params.statementRefs) {
        params.statementRefs.split(',').forEach(item => {
          this.filter.selectedRefs.push({
            key: item,
            value: item
          })
        })
      }


      if (params.tracks) {
        params.tracks.split(',').forEach(item => {
          this.filter.selectedTracks.push({
            key: item,
            value: item
          })
        })
      }
      
      if (params.albums) {
        params.albums.split(',').forEach(item => {
          this.filter.selectedAlbums.push({
            key: item,
            value: item
          })
        })
      }
      
      this.$http
        .get('analytics/accounting-periods')
        .then(response => {
          let result = response.data
          this.apOptions = []
          if (result.length == 0) return

          result.forEach(item => {
            this.apOptions.push({
              value: item.id,
              text: item.description
            })
          })
          
          if (params.accountingPeriod) {
            this.filter.apFrom = params.accountingPeriod
            this.filter.apTo = params.accountingPeriod
          } else {
            this.filter.apFrom = this.apOptions[this.apOptions.length-1].value
            this.filter.apTo = this.filter.apFrom
          }
          this.filterSerial++
        }).catch(() => {
          this.apError = 'An error occurred while loading Accounting Periods. If the problem persists, please contact support.'
        })
    }
  }
</script>

<style scoped>
  .btn { white-space: nowrap; }

  .custom-select {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
</style>
